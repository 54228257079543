import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "../Constant";
import { doGET, doPOST } from "../../../util/HttpUtil";

class CurrencySelect {
    currencies = [];
    constructor() {
        makeAutoObservable(this);
    }
    fetch = async function (filterData) {
        if (this.currencies.length > 0)
            return;
        try {
            const response = await doGET(ENDPOINTS.getAllCurrencies);

            if (response.status === 200) {
                this.currencies = (response.data).map((k) => {
                    return { value: k?.name, label: k?.name }
                });
            } else {
                this.error = response.data;
            }
        } catch (err) {
            this.error = err;
        }
    };
}

const CurrencySelectService = new CurrencySelect();
export default CurrencySelectService;
