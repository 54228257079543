import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import User from "../../../pages/Users/User";
import { DialogContext } from "../../../store/context/DialogContext";
import { I18nContext } from "../../../store/context/i18nContext";
import { SelectField } from "../../SelectField/SelectField";
import { SelectFieldMultiple } from "../../SelectField/SelectFieldMultiple";
import { BottomBarButton, Modal, SearchButton, ModalComponent } from "../../index";
import UserSelectService from "./UserSelectService";

const UserSelect = ({
    style,
    isDisabled,
    className,
    onChange,
    required,
    value,
    label,
    module,
    showErr,
    outline,
    inline,
    borderNone,
    multi,
    ...props
}) => {
    const [users, setUsers] = useState([]);
    const [modal, setModal] = useState(false);
    const { showError } = useContext(DialogContext)
    const { t } = useContext(I18nContext)

    const fetchUsers = async () => {
        try {
            await UserSelectService.fetch();
        } catch (error) {
            showError("Error", error)
        }
    };

    useEffect(() => {
        if (UserSelectService.users.length === 0) fetchUsers();
    }, []);

    const updateUsers = () => {
        setUsers(
            toJS(UserSelectService.users)?.map((user) => {
                return {
                    ...user,
                    value: user._id,
                    label: user?.name
                };
            }).filter(Boolean)
        );
    }

    useEffect(() => {
        updateUsers();
    }, [UserSelectService.users]);

    const openModal = () => {
        setModal(true);
    };

    const closeModal = () => {
        setModal(false);
    };

    const selectUser = (selectedValue) => {
        onChange({ value: multi ? selectedValue[0] : selectedValue });
        closeModal();
    };

    const selectUsers = (selectedValues) => {
        onChange(selectedValues.map((c) => c?.value ?? c));
    };

    const filterOption = useCallback((option, inputValue) => {
        return (
            !inputValue ||
            option.data?.code?.toLowerCase().includes(inputValue.toLowerCase()) ||
            option.data?.address.city?.toLowerCase().includes(inputValue.toLowerCase())
        );
    }, []);
    const renderSelectField = ({ disabled }) => (
        <SelectField
            filterOption={filterOption}
            style={style}
            isDisabled={isDisabled || disabled}
            changeOptionsData={openModal}
            bottomBarChildren={() => (
                <BottomBarButton onClick={openModal} title={t("Manage Users")} />
            )}
            outline={outline}
            // showBottomBar={true}
            className={className}
            onChange={onChange}
            data={users}
            required={required}
            value={value}
            label={label || (!isDisabled && t("User"))}
            error={!value ? t(`Please Select ${label || "User"}`) : ""}
            showErr={showErr}
            inline={inline}
            borderNone={borderNone}
            RightComponent={() => (
                <SearchButton onClick={openModal} />
            )}
            {...props}
        />
    );

    const renderSelectFieldMultiple = () => (
        <SelectFieldMultiple
            filterOption={filterOption}
            isDisabled={isDisabled}
            style={style}
            bottomBarChildren={() => (
                <BottomBarButton onClick={openModal} title={t("Manage Users")} />
            )}
            outline={outline}
            // showBottomBar={true}
            className={className}
            onChange={selectUsers}
            data={users}
            value={value ? users.filter((c) => value?.includes(c?.value)) : ""}
            error={!value ? t(`Please Select ${label || "Users"}`) : ""}
            required={required}
            label={label || (!isDisabled && t("Select Users"))}
            showErr={showErr}
            RightComponent={() => (
                <SearchButton onClick={openModal} />
            )}
        />
    );

    return (
        <>
            {modal && (
                <ModalComponent
                    position={"top"}
                    size={"large"}
                    onToggle={closeModal}
                    isOpen={modal}
                >
                    <ModalComponent.Title>
                        {t("User")}
                    </ModalComponent.Title>

                    <ModalComponent.Body>
                        <User
                            value={value}
                            insidePane={true}
                            multiMode={multi}
                            onSelect={multi ? selectUsers : selectUser}
                        />
                    </ModalComponent.Body>

                </ModalComponent>
            )}


            {users.length > 0
                ? multi
                    ? renderSelectFieldMultiple()
                    : renderSelectField({ disabled: false })
                : renderSelectField({ disabled: true })}
        </>
    );
};

export default observer(UserSelect);
