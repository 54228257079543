import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";

export default function AddNewButton(props) {
  const size = props?.size || 24;
  const style = props?.style || {};
  let navigate = useNavigate();

  return (
    <div
      style={{
        color: "primary",
        display: "inline-block",
        maxWidth: "fit-content",
        ...style,
      }}
      onClick={() => {
        if (props?.link) navigate(props?.link);
        props?.onClick();
      }}
    >
      <Button
        type="button"
        className={"btn ms-2 btn-primary " + props?.className}
        style={{ height: size * 1.25, width: size * 3.25, padding: "2px" }}
      >
        {/* <i style={{ fontSize: size }} className="bx bx-plus" /> */}
        Add New

      </Button>
    </div>
  );
}
