import { useNavigate } from "react-router-dom";

const EditLink = (props) => {
  let navigate = useNavigate();
  return (
    <div
      className={props?.className + " mx-2"}
      style={{ display: "inline-block", color: "secondary", cursor: "pointer", maxWidth : "16px" }}
      onClick={() => {
        if (props?.to) navigate(props?.to);
        props?.onClick();
      }}
    >
      <i className="uil uil-pen font-size-18" />
    </div>
  );
};
export default EditLink;
