import React, { memo } from "react";
import { useState, useEffect } from "react";
// import Swal from "sweetalert2";

const Timer = (props) => {
  const { initialMinute = 0, initialSeconds = 0, resetTime } = props;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  useEffect(() => {
    let myInterval = setTimeout(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }

      if (minutes === 0 && seconds === 0) {
        props?.setDisable(false);
        // Swal.fire("Time's Out");
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [minutes, seconds, props]);

  // useEffect(() => { setMinutes(5) }, [resetTime])

  return (
    <>
      {minutes === 0 && seconds === 0 ? (
        <span style={{ color: "white" }}> 00:00</span>
      ) : (
        <span style={{ color: "white" }}>
          {" "}
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </span>
      )}
    </>
  );
};

export default memo(Timer);
