import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "../Constant";
import { doGET, doPOST } from "../../../util/HttpUtil";

class CountryLangSelect {
    langs = [];
    constructor() {
        makeAutoObservable(this);
    }
    fetch = async function (filterData) {
        if (this.langs.length > 0)
            return;
        try {
            const response = await doGET(ENDPOINTS.getAllCountryLangs);
            if (response.status === 200) {

                this.langs = response.data;
            } else {
                this.error = response.data;
            }
        } catch (err) {
            this.error = err;
        }
    };
}

const CountryLangSelectService = new CountryLangSelect();
export default CountryLangSelectService;
