import React, { useContext, useEffect, useState } from 'react'
import Logo from "../../../../../assets/images/Logo.svg";
import { DialogContext } from '../../../../../store/context/DialogContext';
import { doGET } from '../../../../../util/HttpUtil';
import ProofOfDocuments from '../../BusinessLiteForms/components/ProofOfDocument';
import SubmitButton from '../../../../../components/Buttons/SubmitButton';
import { FormEndpoints } from '../../ExtraQuestions';

const ApplicationDocRedo = ({ type, formType, formId, asLabel, recordId }) => {

    const [reqdApplicationDocs, setReqdApplicationDocs] = useState([])
    const [loading, setLoading] = useState([])
    const [documentTypes, setDocumentTypes] = useState([])
    const { showMessage, showError, showConfirm } = useContext(DialogContext)
    const [uboDetail, setUboDetail] = useState(null);

    const fetchUBOs = async () => {
        setLoading(true);
        try {
            const uboResponse = await doGET(`/api/ubo/grid?application_id=${recordId}`);
            const obj = {};
            (uboResponse?.data?.rows ?? []).forEach((v) => {
                obj[v?._id] = v;
            });
            setUboDetail(obj);
        } catch (error) {
            console.error('Error fetching ubo details:', error);

        } finally {
            setLoading(false);
        }
    }


    const fetch = async () => {
        setLoading(true)
        try {
            const response = await doGET(`/api/application-doc/redo/list?form_id=${formId}`)
            if (response?.status == 200) {
                setReqdApplicationDocs(response?.data)
            }
        } catch (error) {
            showError(error)
        } finally {
            setLoading(false)
        }
    }


    const fetchDocumentTypes = async () => {
        try {
            const docTypeResponse = await doGET(`/api/doc-type/grid?rows=-1`);
            if (docTypeResponse?.status == 200) {
                setDocumentTypes(docTypeResponse?.data?.rows.reduce((acc, obj) => {
                    if (obj?.key && !acc[obj?.key]) {
                        acc[obj?.key] = obj?.name;
                    } else if (obj?._id && !acc[obj?._id]) {
                        acc[obj?._id] = obj?.name;
                    }
                    return acc;
                }, {}))

    
            }
        } catch (error) {
            showError(error)
        }
    }


    useEffect(() => {
        fetch()
        fetchUBOs();
        fetchDocumentTypes();
    }, [recordId])

    const handleSubmit = async () => {
        setLoading(true)
        try {
            const submitResponse = await doGET(`/api/${FormEndpoints[formType]}/submit?id=${formId}&markDocSubmit=true`)
            if (submitResponse?.status == 200) {
                showMessage("Submitted")
                await new Promise(resolve => setTimeout(resolve, 3000));
                window.location.reload();
            }
        } catch (error) {
            showError(error)
            setLoading(false)

        }
    }

    const groupDocumentsByUbo = (documents) => {
        const grouped = { reqdApplicationDocs: [] };

        documents.forEach(doc => {
            if (doc?.ubo_id) {
                if (!grouped[doc?.ubo_id]) {
                    grouped[doc?.ubo_id] = [];
                }
                grouped[doc?.ubo_id].push(doc);
            } else {
                grouped?.reqdApplicationDocs.push(doc);
            }
        });

        return grouped;
    };

    const groupedDocuments = groupDocumentsByUbo(reqdApplicationDocs);

    return (
        <div className={'d-flex justify-content-center align-items-center'}>
            <div className={'form-content '}>


                <div className='d-flex flex-column  justify-content-center  align-items-center  '>
                    {<img
                        src={Logo}
                        alt="fv-bank"
                        height="50"
                        style={{ marginBottom: "10px" }}
                        className="logo logo-dark"
                    />}
                    <div style={{ margin: "10px 0px 10px 0px", fontSize: "24px", fontWeight: "500" }}>{type}</div>
                    <div style={{ margin: "0px 0px 30px 0px", fontSize: "16px", fontWeight: "500" }}>To continue with your application we require following documents.</div>
                    <div className='w-100'>
                        {groupedDocuments.reqdApplicationDocs?.length ? <>
                            <div className='mb-2'><b>Business Application Documents</b></div>
                            <div className="fv_application_document-group">
                                {groupedDocuments.reqdApplicationDocs.map((reqdApplicationDoc, index) => (
                                    <ProofOfDocuments
                                        isAskForInfo={true}
                                        recordId={recordId}
                                        asLabel={asLabel}
                                        remark={reqdApplicationDoc?.remark}
                                        seq={reqdApplicationDoc?.seq}
                                        single={reqdApplicationDoc?.type === "ProofOfAddress"}
                                        supportedFiles={["image", "pdf"]}
                                        label={documentTypes?.[reqdApplicationDoc?.type] ?? reqdApplicationDoc?.type }
                                        field={documentTypes?.[reqdApplicationDoc?.type] ?? reqdApplicationDoc?.type }
                                        showCategory={documentTypes?.[reqdApplicationDoc?.type] ? reqdApplicationDoc?.type : false}
                                        type={reqdApplicationDoc?.type} //
                                        ubo_id={reqdApplicationDoc?.ubo_id} // 
                                    />
                                ))}


                            </div>    </> : null}

                        {Object.keys(groupedDocuments)
                            .filter(key => key !== 'reqdApplicationDocs')
                            .map((ubo_id, index) => (
                                <div key={index}>
                                    <div className='mb-2'>
                                        <b>Application Documents For {uboDetail?.[ubo_id]?.firstName ?? ""} {uboDetail?.[ubo_id]?.lastName ?? ""}</b>
                                    </div>
                                    {groupedDocuments[ubo_id].map((reqdApplicationDoc, docIndex) => (
                                        <div key={docIndex} className="w-100">
                                            <div className="fv_application_document-group w-100">
                                                <ProofOfDocuments
                                                    isAskForInfo={true}
                                                    recordId={recordId}
                                                    asLabel={asLabel}
                                                    remark={reqdApplicationDoc?.remark}
                                                    seq={reqdApplicationDoc?.seq}
                                                    single={reqdApplicationDoc?.type === "ProofOfAddress"}
                                                    supportedFiles={["image", "pdf"]}
                                                    label={documentTypes?.[reqdApplicationDoc?.type] ?? reqdApplicationDoc?.type}
                                                    field={documentTypes?.[reqdApplicationDoc?.type] ?? reqdApplicationDoc?.type}
                                                    showCategory={documentTypes?.[reqdApplicationDoc?.type]  ? reqdApplicationDoc?.type : false}
                                                    type={reqdApplicationDoc?.type}
                                                    ubo_id={reqdApplicationDoc?.ubo_id}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}

                    </div>

                    <SubmitButton
                        loading={loading}
                        onClick={(e) => {

                            handleSubmit(e);
                        }}
                    />




                </div>
            </div>
        </div>
    )
}

export default ApplicationDocRedo