import React from 'react';
import ReactInputMask from 'react-input-mask';

const InputFieldWrapper = ({ masking, maxLength, ...props }) => {
  // If masking is true, exclude the maxLength property to avoid the warning
  const inputProps = masking ? { ...props } : { maxLength, ...props };

  if (masking) {
    return <ReactInputMask {...inputProps} />;
  }

  return <input {...inputProps} />;
};

export default InputFieldWrapper;
