import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "../Constant";
import { doGET, doPOST } from "../../../util/HttpUtil";

class TariffNameSelect {
    tariffNames = [];
    constructor() {
        makeAutoObservable(this);
    }
    fetch = async function (filterData) {
        try {
            const response = await doGET(ENDPOINTS.getAllTariffNames);
            if (response.status === 200) {

                this.tariffNames = response.data.map((k) => {
                    return { ...k, label: k.name, value: k.name }
                });
            } else {
                this.error = response.data;
            }
        } catch (err) {
            this.error = err;
        }
    };
}

const TariffNameSelectService = new TariffNameSelect();
export default TariffNameSelectService;
