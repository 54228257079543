import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { FaFilePdf, FaDownload } from 'react-icons/fa';
import "./styles.scss"
import Image from '../Image/Image';
import PDFViewer from '../Image/PDF';
import _ from 'lodash';

export const isPdf = (doc) => {
  const docType = _.toLower(doc?.type?.split('/')[0] || '');
  const docName = _.toLower(doc?.name || '');
  const docKey = _.toLower(doc?.key || '');
  const docString = _.toLower(doc || '');

  return (
    (docType === 'application' && docName.endsWith('.pdf')) ||
    docKey.endsWith('.pdf') ||
    docString.endsWith('.pdf')
  );
};


const DocViewer = ({ documents = [] }) => {
  const [modal, setModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const toggleModal = () => setModal(!modal);

  const handleDocumentClick = (document) => {
    setSelectedDocument(document);
    toggleModal();
  };





  return (
    <>
      {documents.map((document, index) => (
        <div key={index} className="fv_doc_field_file_link" onClick={() => handleDocumentClick(document.key)}>
          <FaFilePdf className="fv_doc_field_icon" /> Document {index + 1}
        </div>
      ))}

      <Modal size="xl" isOpen={modal} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>View Document</ModalHeader>
        <ModalBody className="fv_doc_field_modal_body  ">
          {selectedDocument ? (
            isPdf(selectedDocument) ? (
              <>
                <PDFViewer src={selectedDocument.key ?? selectedDocument} alt="Document" />
              </>
            ) : (
              <div className='d-flex justify-content-center align-items-center'>
                <Image src={selectedDocument.key ?? selectedDocument} alt="Document" />
              </div>

            )
          ) : <p>No document selected</p>}
        </ModalBody>
      </Modal>
    </>
  );
};

export default DocViewer;
