import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./LanguageConstant";
import { doGET, doPOST } from "../../../util/HttpUtil";
import Image from "../../Image/Image";

class LanguageSelect {
    languages = [];
    constructor() {
        makeAutoObservable(this);
    }
    fetch = async function (filterData) {
        if (this.languages.length > 0)
            return;
        try {
            const response = await doGET(ENDPOINTS.grid());
            if (response.status === 200) {
                this.languages = response.data?.rows;
            } else {
                this.error = response.data;
            }
        } catch (err) {
            this.error = err;
        }
    };
}

const LanguageSelectService = new LanguageSelect();
export default LanguageSelectService;
