import { observer } from "mobx-react-lite";
import { Button } from "reactstrap";

const Modal = ({ style, className, onChange, children, modalSwitch, show }) => {
  return (
    <div style={{ position: "fixed" }} className="__backdrop">
      <div
        style={{ position: "relative", ...style }}
        className={modalSwitch ? "" : "__modal " + className}
      >
        {modalSwitch ?
          null :
          <Button
            color="danger"
            className="waves-effect waves-light __modal-close-btn "
            onClick={() => {
              onChange();
            }}
            style={{ marginleft: "200px", }}
          >
            <i style={{ fontSize: "38px" }} className="bx bx-x" />
          </Button>}

        <div style={{ padding: "30px", }}>  {children}</div>
      </div>
    </div >
  );
};

export default observer(Modal);
