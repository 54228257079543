import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "../Constant";
import { doGET, doPOST } from "../../../util/HttpUtil";

class TimeZoneSelect {
    timeZones = [];
    constructor() {
        makeAutoObservable(this);
    }
    fetch = async function (filterData) {
        if (this.timeZones.length > 0)
            return;
        try {
            const response = await doGET(ENDPOINTS.getAllTimeZones);
            if (response.status === 200) {

                this.timeZones = response.data.map((v) => { return { label: v, value: v } });
            } else {
                this.error = response.data;
            }
        } catch (err) {
            this.error = err;
        }
    };
}

const TimeZoneSelectService = new TimeZoneSelect();
export default TimeZoneSelectService;
