import React from "react";

const CheckButton = (props) => {
  return (
    <i
      role="button"
      className="mdi mdi-check-bold text-success font-size-20 mx-2"
      onClick={props?.onClick}
      style={{zIndex : "999"}}
    />
  );
};
export default CheckButton;
