import React from "react";
import { IoArrowBackOutline } from "react-icons/io5";



export default function BackArrow(props) {
  return (
    <div
      style={{ width: 'max-content'}}
      className="edit_back d-inline d-md-none mt-5  pt-5 "
      onClick={() => props?.onClick()}
    >
      {/* <i style={{ fontSize: "14px"}} className="mt-5 dripicons-arrow-left" /> */}
      <IoArrowBackOutline style={{fontSize: "14px"}}/>

      {/* <i style={{ fontSize: "14px"}} className="mt-5 dripicons-arrow-left" /> */}


    </div>
  );
}
