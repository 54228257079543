import { makeAutoObservable } from "mobx";


class Service {
    uiPrefs = {};
   
    constructor() {
        makeAutoObservable(this);
    }


    updatePrefs = (key, prefs) => {
        this.uiPrefs[key] = prefs
    };
}

const UIPrefService = new Service();
export default UIPrefService;
