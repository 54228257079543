import _ from 'lodash';
import React, { useContext, useState } from 'react';
import { InputField, SelectField } from '../../../../../components';
import NextButton from '../../../../../components/Buttons/NextButton';
import AntDateSelect from '../../../../../components/DateSelects/AntDateSelect';
import PhoneWithOtpValidation from '../../../../../components/PhoneNum/PhoneWithOtpValidation';
import { DialogContext } from '../../../../../store/context/DialogContext';
import SSNField from '../../../components/SSNField/SSNField';
import UserNameField from '../../../components/userName/UserNameInputField';
import { useTranslation } from '../../../../../store/context/TranslationContext';
import { addressDesc } from '../../constants';

export function validateEmail(email) {
    return /^\S+@\S+\.\S+$/.test(email);
}

export function validatePhoneNumber(phone) {
    return /^[\d\-\(\)\s]+$/.test(phone);
}

export function validateSSN(ssn) {
    return /^(?!000|666)[0-8][0-9]{2}(?:-)?(?!00)[0-9]{2}(?:-)?(?!0000)[0-9]{4}$/.test(ssn);
}

const validateSSNAndReturnError = (ssn) => {
    if (!validateSSN(ssn)) {
        return "Please enter a valid SSN.";
    }
    return "";
};

const errorMessages = {
    firstName: "Please enter a valid First Name.",
    lastName: "Please enter a valid Last Name.",
    middleName: "Please enter a valid Middle Name.",
    mobilePhone: "Please enter a valid Mobile Phone.",
    email: "Please enter a valid Email.",
    cyclosUserName: "Please enter a valid Username.",
    ssn: "Please enter a valid SSN.",
    taxId: "Please enter a valid Tax ID or Passport Number.",
    dob: "Please select a valid Date of Birth.",
    gender: "Please select gender.",
    nationality: "Please select nationality.",
    "address.buildingNumber": "Please enter a valid Building and Number.",
    "address.street": "Please enter a valid Street name.",
    "address.pinCode": "Please enter a valid Zip Code.",
    "address.city": "Please enter a valid City.",
    "address.state": "Please enter a valid State.",
    "address.country": "Please select country."
};

export const handleValidation = (field, value) => {
    const nameRegex = /^[a-zA-Z\s]*$/;

    if (field === 'middleName' && value?.length) {
        if (value?.length < 2 || value?.length > 50) {
            return `Your ${_.capitalize(field.replace(/Name$/, ''))} Name should be between 2 and 50 characters.`;
        }
        if (value && !nameRegex.test(value)) {
            return "Please enter a valid Middle Name with only alphabetic characters.";
        }
        if (value && value.split(' ').some(word => word.length > 50)) {
            return "Each word in the Middle Name should be less than 50 characters.";
        }
        return "";
    }

    if (['firstName', 'lastName']?.includes(field)) {
        if (!value) {
            return errorMessages[field];
        }
        if (value.length < 2 || value.length > 50) {
            return `Your ${_.capitalize(field.replace(/Name$/, ''))} Name should be between 2 and 50 characters.`;
        }
        if (!nameRegex.test(value)) {
            return errorMessages[field];
        }
        if (value.split(' ').some(word => word.length > 50)) {
            return `Each word in the ${_.capitalize(field.replace(/Name$/, ''))} Name should be less than 50 characters.`;
        }
        return "";
    }

    if (field === 'cyclosUserName' && (!value || !value?.length)) {
        return errorMessages[field];
    }

    if (field === 'mobilePhone') {
        return validatePhoneNumber(value) ? "" : errorMessages[field];
    }

    if (!value || !value?.length) {
        return errorMessages[field];
    }

    return "";
};

const UserDetailsForm = ({
    handleChangeViaObject,
    onOtpVerify,
    hideButtons,
    asLabel,
    data,
    handleChange,
    setData,
    handleUpdate,
    hasError,
    setHasError,
    setErrorMessage,
    loading,
    setLoading,
    cyclosFields,
}) => {

    const [isValidSSN, setIsValidSSN] = useState(true);
    const [isValidUsername, setIsValidUsername] = useState();
    const { showError } = useContext(DialogContext);
    const [centralErrors, setCentralErrors] = useState({});
    const { t, } = useTranslation();

    const handleSubmit = () => {
        let errors = [];

        const requiredFields = [
            'firstName', 'lastName', 'address.buildingNumber', 'address.street',
            'address.pinCode', 'address.city', 'address.state', 'address.country', 'mobilePhone',
            'cyclosUserName', 'gender', 'nationality', 'dob',
        ];

        if ((["USA", "US"]?.includes(data?.nationality) || ["USA", "US"]?.includes(data?.address?.country))) {
            requiredFields.push('ssn');
            delete data.taxId;
        } else {
            requiredFields.push('taxId');
            delete data.ssn;
        }

        if (data?.middleName?.length) {
            if (!requiredFields.includes('middleName')) {
                requiredFields.splice(1, 0, 'middleName');
            }
        }

        requiredFields.forEach(field => {
            const fieldValue = _.get(data, field);
            if ((field === 'mobilePhone' && (centralErrors?.["mobilePhone"])?.hasError)) {
                errors.push(centralErrors["mobilePhone"]?.errorMsg);
            } else if ((field === 'mobilePhone' && !validatePhoneNumber(fieldValue))) {
                errors.push(`Please enter a valid Mobile Phone`);
            } else if ((field === 'mobilePhone' && !data?.phoneVerifiedViaOTP)) {
                errors.push(t("Please verify mobile phone with OTP"));
            } else if ((field === 'ssn' && !validateSSN(fieldValue))) {
                errors.push(`Please enter a valid SSN number.`);
            } else if (field === 'cyclosUserName' && !isValidUsername) {
                errors.push(`Please enter a valid userName`);
            } else {
                const fieldError = handleValidation(field, fieldValue);
                if (fieldError) errors.push(fieldError);
            }
        });

    
        if (!isValidSSN) errors.push(t(errorMessages['ssn']));  
        

        if (errors.length > 0) {
            setHasError(true);
            setErrorMessage(errors.join(' '));
            showError((errors[0]));
            return;
        }

        setHasError(false);
        handleUpdate({ nextStep: 2 });
    };

    const addressLabels = {
        buildingNumber: "Building and Number",
        street: "Street name",
        pinCode: "Zip Code",
        city: "City",
        state: "State / Province",
    };

    return (
        <div className="row">
            <InputLabel label={t("Name")} required />
            {['firstName', 'middleName', 'lastName'].map(field => (
                <InputField
                    key={field}
                    placeholder={t(_.capitalize(field.replace(/Name$/, '')))}
                    value={data?.[field]}
                    onChange={(v) => handleChange(field, v)}
                    error={t(handleValidation(field, data?.[field]))}
                    showErr={field == "middleName" ? (hasError && data?.[field]?.length) : hasError}
                    type="text"
                    className="col-sm-4 mt-0"
                    isDisabled={asLabel}
                    maxLength={50}
                    regex={/^[a-zA-Z\s]*$/}
                />
            ))}

            <InputLabel label={t("Address")} required />
            {['buildingNumber', 'street', 'pinCode'].map(field => (
                <InputField
                    key={field}
                    placeholder={t(addressLabels[field] || _.startCase(field))}
                    value={_.get(data, `address.${field}`)}
                    onChange={(v) => handleChange(`address.${field}`, v)}
                    error={t(handleValidation(`address.${field}`, _.get(data, `address.${field}`)))}
                    showErr={hasError}
                    type="text"
                    maxLength={40}
                    className="col-12"
                    isDisabled={asLabel}
                    desc={t(addressDesc[field])}
                    regex={field === "pinCode" ? /^[a-zA-Z0-9]*$/ : /^[A-Za-z0-9\s\-\/\.,:_]*$/}
                />
            ))}



            {['city', 'state'].map(field => (
                <InputField
                    key={field}
                    placeholder={t(addressLabels[field] || _.startCase(field))}
                    value={_.get(data, `address.${field}`)}
                    onChange={(v) => handleChange(`address.${field}`, v)}
                    error={t(handleValidation(`address.${field}`, _.get(data, `address.${field}`)))}
                    showErr={hasError}
                    type="text"
                    className="col-12 col-sm-4"
                    maxLength={40}
                    isDisabled={asLabel}
                    desc={t(addressDesc[field])}
                    regex={/^([a-zA-Z\s\-']*)$/}
                />
            ))}

            <SelectField
                value={data?.address?.country}
                onChange={(v) => handleChange(`address.country`, v?.value)}
                error={t(handleValidation('address.country', data?.address?.country))}
                showErr={hasError}
                data={cyclosFields?.countryOfBirth?.options}
                noLabel
                placeholder="Country"
                label="Country"
                className="col-12 col-sm-4 mt-3"
                isDisabled={asLabel}
                desc={addressDesc["country"]}
            />

            <PhoneWithOtpValidation
                group="Individuals_Lite"
                className="mt-2"
                required
                label={t("Mobile Phone")}
                placeholder={t("Mobile Phone")}
                form_id={data?._id}
                formData = {data}
                value={{
                    phone: data?.mobilePhone,
                    countryCode: data?.mobileCountryCode,
                    phoneVerifiedViaOTP: data?.phoneVerifiedViaOTP
                }}
                onChange={(v) => {
                    handleChangeViaObject({
                        mobilePhone: v?.phone,
                        mobileCountryCode: v?.countryCode,
                        phoneVerifiedViaOTP: v?.phoneVerifiedViaOTP
                    });
                }}
                onOtpVerify={onOtpVerify}
                isDisabled={asLabel}
                notifyParent={() => {

                }}
                onError={(v) => {
                    setCentralErrors((prevErrors) => ({
                        ...prevErrors,
                        [`mobilePhone`]: v,
                    }));
                }}
                err={t(handleValidation('mobilePhone', data?.mobilePhone))}
                showErr={hasError}
            />

            <InputField
                placeholder=""
                value={data?.email}
                onChange={() => { }}
                type="email"
                label={t("E-mail")}
                isDisabled
                className="mt-4"
                required
            />

            <UserNameField
                isValidUsername={isValidUsername}
                setIsValidUsername={setIsValidUsername}
                loading={loading}
                handleLoading={setLoading}
                value={data?.cyclosUserName}
                applicationId={data?._id}
                onChange={(v) => handleChange(`cyclosUserName`, v)}
                email={data?.email}
                required
                isDisabled={asLabel}
                formType={data?.formType}
                className="col-sm-12 mt-0"
                placeholder=""
                showErr={hasError}
                err={t(handleValidation('cyclosUserName', data?.cyclosUserName))}
            />

            <SelectField
                key="gender"
                data={cyclosFields?.gender?.options}
                value={data?.gender}
                onChange={(v) => handleChange('gender', v?.value)}
                error={t(handleValidation('gender', data?.gender))}
                showErr={hasError}
                label={t("Gender")}
                placeholder=""
                isDisabled={asLabel}
                required
            />


            <SelectField
                key="nationality"
                data={cyclosFields?.nationality?.options}
                value={data?.nationality}
                onChange={(v) => handleChange('nationality', v?.value)}
                error={t(handleValidation('nationality', data?.nationality))}
                showErr={hasError}
                label={t("Nationality")}
                placeholder={t("Nationality")}
                isDisabled={asLabel}
                required
                className="col-12 mt-3"
            />

            <AntDateSelect
                placeholder=""
                value={data?.dob}
                onChange={(v) => setData({ ...data, dob: v })}
                error={t(handleValidation('dob', data?.dob))}
                showErr={hasError}
                className="col-sm-12 col-12 pt-2 pb-1"
                label={t("Date of Birth")}
                isDisabled={asLabel}
                required
                format="dd/mm/yyyy"
                maxDate={new Date()}

            />

            {!(["USA", "US"]?.includes(data?.nationality) || ["USA", "US"]?.includes(data?.address?.country)) && (
                <InputField
                    value={data?.taxId}
                    onChange={(v) => handleChange('taxId', v)}
                    error={t(handleValidation('taxId', data?.taxId))}
                    showErr={hasError}
                    label={t("Tax ID or Passport Number")}
                    placeholder={t("Tax ID or Passport Number")}
                    type="text"
                    className="col-12"
                    isDisabled={asLabel}
                    required
                />
            )}

            {(["USA", "US"]?.includes(data?.nationality) || ["USA", "US"]?.includes(data?.address?.country)) && (
                <SSNField
                    value={data?.ssn}
                    onChange={(v) => handleChange('ssn', v)}
                    showErr={hasError}
                    className="col-12"
                    isDisabled={asLabel}
                    required
                    notifyParent={setIsValidSSN}
                    group="Individuals_Lite"
                    applicationId={data?._id}
                    err={validateSSNAndReturnError(data?.ssn)}
                />
            )}

            {!hideButtons && (
                <div className="d-flex justify-content-end mt-3">
                    <NextButton loading={loading} onClick={handleSubmit} />
                </div>
            )}
        </div>
    );
};

export const InputLabel = ({ label, required }) => (
    <div style={{ minWidth: "120px", fontSize: 16, margin: "5px 0px 0px 5px", fontWeight: "500" }}>
        {label}
        {required && <font color="red">*</font>}
    </div>
);

export default UserDetailsForm;
