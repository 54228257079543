import { makeAutoObservable, toJS } from "mobx";
import { doGET } from "../../../util/HttpUtil";
import { ENDPOINTS } from "./CitySelectConstant";

class CitySelect {
  cities = {};
  constructor() {
    makeAutoObservable(this);
  }
  fetch = async function (country, state) {
    if (this.cities[country + "/" + state]) return;
    try {
      const response = await doGET(
        ENDPOINTS.getCitiesByCountryAndState(country, state)
      );
      if (response.status === 200) {
        this.cities = {
          ...this.cities,
          [country + "/" + state]: response.data.rows.map((a) => {
            return { label: a.name, value: a.name };
          }),
        };
      } else {
        this.error = response.data;
      }
    } catch (err) {
      this.error = err;
    }
  };
  getCityByCountry = async function (country) {
    if (this.cities[country]) return;
    const response = await doGET(ENDPOINTS.getCitiesByCountryAndState(country));
    if (response.status === 200) {
      this.cities = {
        ...this.cities,
        [country]: response.data.map((a) => {
          return { label: a, value: a };
        }),
      };
    } else {
      this.error = response.data;
    }
  };
}

const CitySelectService = new CitySelect();
export default CitySelectService;
