import React, { useState, useRef, useEffect } from 'react';
import 'flag-icons/css/flag-icons.min.css';
import './LanguagePicker.css';
import { useTranslation } from '../../store/context/TranslationContext';
import { LuLanguages } from 'react-icons/lu'; // Import the icon

const options = [
    { value: 'en', label: 'English', icon: 'gb' },
    // { value: 'hi', label: 'हिन्दी', icon: 'in' }, 
    { value: 'es', label: 'Español', icon: 'es' },  
    { value: 'jp', label: '日本語', icon: 'jp' },
];


const LanguagePicker = () => {
    const { t, changeLanguage, language } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const selectedOption = options.find((o) => o.value === language) || options[0];
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setIsOpen((prevIsOpen) => !prevIsOpen);
    };

    const handleOptionClick = (option) => {
        changeLanguage(option.value);
        setIsOpen(false);
    };

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="fv_language-selector" ref={dropdownRef}>
            <div className="fv_language-dropdown">
                <button
                    className="fv_language-toggle"
                    onClick={toggleDropdown}
                    type="button"
                >
                    <div className='fv_language-icon-body'> <LuLanguages className="fv_language-icon" /> </div>{/* LuLanguages icon */}
                    <span className={`fi fi-${selectedOption.icon}`}></span>
                    <span className="fv_language-selected">
                        {selectedOption.label} {/* Show selected label */}
                    </span>
                </button>
                {isOpen && (
                    <ul className="fv_language-menu">
                        {options.map((option) => (
                            <li
                                key={option.value}
                                className="fv_language-item"
                                onClick={() => handleOptionClick(option)}
                            >
                                <span className={`fi fi-${option.icon}`}></span>
                                <span className="fv_language-option-label">
                                    {option.label}
                                </span>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default LanguagePicker;
