import React from 'react';
import { Button } from "reactstrap";

const BottomBarButton = ({ onClick, title }) => {
    return <Button
        color="primary"
        className="btn btn-primary btn-lg btn-block waves-effect waves-light mb-1 px-2 pb-1"
        style={{ fontSize: 12 }}
        onClick={onClick}
    >
        {title}
    </Button>
}

export default BottomBarButton;
