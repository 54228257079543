import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import './style.css';
import MaskInput from '../MaskInput';
import { MASK } from '../MaskInput/utils';

const YearPicker = (props) => {
  const {
    value,
    onChange,
    isDisabled,
    placeholder,
    label,
    error,
    className,
    required,
    minYear = 1900,
    maxYear = moment().year() + 20,
    format = "yyyy",
    ...rest
  } = props;

  const [selectedYear, setSelectedYear] = useState(
    value ? new Date(value, 0) : null
  );
  const [inputError, setInputError] = useState('');

  useEffect(() => {
    if (value) {
      setSelectedYear(new Date(value, 0));
    } else {
      setSelectedYear(null);
    }
  }, [value]);

  const handleYearChange = (date) => {
    if (date) {
      const year = date.getFullYear();
      if (year >= minYear && year <= maxYear) {
        setSelectedYear(date);
        onChange(year.toString());
        setInputError('');
      } else {
        setInputError(`Year must be between ${minYear} and ${maxYear}`);
      }
    } else {
      setSelectedYear(null);
      onChange('');
    }
  };

  return (
    <div className={`d-flex flex-column ${className}`}>
      <DatePicker
        selected={selectedYear}
        onChange={handleYearChange}
        showYearPicker
        dateFormat="yyyy"
        placeholderText={placeholder || 'YYYY'}
        disabled={isDisabled}
        className={`my-form-input w-100 ${inputError ? 'is-invalid' : ''}`}
        wrapperClassName="react-datepicker-wrapper w-100"
        minDate={new Date(minYear, 0)}
        maxDate={new Date(maxYear, 11)}
        required={required}
        customInput={
          <MaskInput
            error={inputError ? inputError : error}
            {...MASK[format]}
            datePicker={true}
            {...props}
          />
        }
        {...rest}
      />
      {(inputError || error) && (
        <div className="invalid-feedback">{inputError || error}</div>
      )}
    </div>
  );
};

export default YearPicker;
