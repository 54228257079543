import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "../../../pages/Users/UserConstant";
import { doGET } from "../../../util/HttpUtil";

class UserSelect {
    users = [];
    constructor() {
        makeAutoObservable(this);
    }
    fetch = async function (props) {
        try {
            const response = await doGET(ENDPOINTS.grid());
            if (response.status === 200) {
                this.users = response?.data?.rows
            } else {
                this.error = response.data;
            }
        } catch (err) {
            this.error = err;
        }
    };
}

const UserSelectService = new UserSelect();
export default UserSelectService;
