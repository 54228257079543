import { observer } from "mobx-react-lite";
import React, { useContext, useState } from "react";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import { Button, Card, Col, Form, Row } from "reactstrap";
import { formatBytes } from "../../components";
import { I18nContext } from "../../store/context/i18nContext";

const FileUpload = (props) => {
  const [selectedFiles, setselectedFiles] = useState([]);
  const { t } = useContext(I18nContext);

  function handleAcceptedFiles(files, i) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setselectedFiles([files[0]]);
    props?.onChange(files[0]);
  }

  return (
    <>
      <div className={props?.className}>
        <Card className="mx-0 px-2">
          <Form>
            <Dropzone
              onDrop={(acceptedFiles) => {
                handleAcceptedFiles(acceptedFiles);
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone">
                  <div className="dz-message needsclick" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <>
                      {" "}
                      <div className="mb-3">
                        <i className="display-4 text-muted uil uil-cloud-upload" />
                      </div>
                      <h4>Drop files here or click to upload.</h4>{" "}
                    </>
                  </div>
                </div>
              )}
            </Dropzone>
            <Row className=" dropzone-preview mt-3">
              {selectedFiles.map((f, i) => {
                return (
                  <Col key={i} className="">
                    <Card
                      style={{ backgroundColor: "#49505799", color: "#fff" }}
                      className="dz-files-height mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                      key={i + "-file"}
                    >
                      <Button
                        color="danger"
                        className="btn-rounded waves-effect waves-light __dz-close-btn"
                        onClick={() => {
                          setselectedFiles([]);
                          props?.onChange(undefined);
                        }}
                      >
                        <i style={{ fontSize: "20px" }} className="bx bx-x" />
                      </Button>
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col>
                            <Link
                              to="#"
                              style={{ color: "#fff" }}
                              className="dz-filename font-weight-bold"
                            >
                              <span className="dz-file-name">
                                {f.name.substring(0, 7)}...
                              </span>
                              <span className="dz-file-fullname">{f.name}</span>
                            </Link>
                            <p className="dz-file-size mb-0">
                              <strong>{f.formattedSize}</strong>
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </Form>
        </Card>
      </div>
    </>
  );
};

export default observer(FileUpload);
