import React, { useContext, useEffect, useState } from 'react';
import UserService from './UserService';
import { DialogContext } from '../../store/context/DialogContext';
import { ThreeBounce } from 'better-react-spinkit';
import { Card, CardBody, CardHeader } from 'reactstrap';
import moment from 'moment';
import './style.css';
import { LuDot } from "react-icons/lu";
import { Tooltip } from 'react-tooltip';

const convertTimeStampToDate = (timestamp) => {
    return moment.unix(timestamp).format('DD-MM-YYYY');
}

const truncateString = (inputString, maxLength) => {
    return inputString.length <= maxLength ? inputString : inputString.slice(0, maxLength) + "...";
}

const Feedback = ({ editId }) => {
    const { showError } = useContext(DialogContext);
    const [loading, setLoading] = useState(false);
    const [comments, setComments] = useState(null);

    const fetchCommentsOfCurrentUser = async () => {
        try {
            setLoading(true);
            const user = await UserService.fetchComments(editId);
            setLoading(false);
            setComments(user);
        } catch (error) {
            showError(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchCommentsOfCurrentUser();
    }, [editId]);

    return (
        <div>
            {loading ? (
                <div className="d-flex justify-content-center align-items-center">
                    <ThreeBounce size={30} color="pink" />
                </div>
            ) : (
                <Card>
                    <CardHeader>Comments</CardHeader>
                    <CardBody>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{ width: "4%" }}>S.No.</th>
                                    <th className='feedback_table_header'>Module</th>
                                    <th className='feedback_table_header'>Date</th>
                                    <th className='feedback_table_header'>Rating</th>
                                    <th className=''>Liked</th>
                                    <th className=''>Disliked</th>
                                    <th className='feedback_table_header'>Remark</th>
                                </tr>
                            </thead>
                            <tbody>
                                {comments?.map((comment, cIndex) => (
                                    <tr key={cIndex}>
                                        <td >{cIndex + 1}</td>
                                        <td className='feedback_table_body'>{comment.recordType}</td>
                                        <td className='feedback_table_body'>{convertTimeStampToDate(comment?.at)}</td>
                                        <td className='feedback_table_body'>{comment.content.rating}</td>
                                        <td className=''>
                                            {comment.content.liked.map((liked, lIndex) => (
                                                <div key={lIndex} className='d-flex justify-content-start align-items-center'>
                                                    <div className='me-1 feedback_dot' />
                                                    <div>{liked}</div>
                                                </div>
                                            ))}
                                        </td>
                                        <td className=''>
                                            <div>
                                                {comment.content.disliked.map((disliked, lIndex) => (
                                                    <div key={lIndex} className='d-flex justify-content-start align-items-center'>
                                                        <div className='me-1 feedback_dot' />
                                                        <div>{disliked}</div>
                                                    </div>
                                                ))}
                                            </div>
                                        </td>
                                        <td className='feedback_table_body'>
                                            {comment.content.remark?.length > 20 ?
                                                <div className="row">
                                                    <Tooltip noArrow style={{
                                                        zIndex: 999999,
                                                        maxWidth: '300px',
                                                        height: "auto",
                                                        backgroundColor: '#F2F2F2',
                                                        color: "black",
                                                        whiteSpace: 'pre-wrap',
                                                        overflow: 'auto',
                                                        fontWeight: 'bold'
                                                    }} anchorSelect={`#not-clickable-${comment?._id}`}>
                                                        {comment.content.remark}
                                                    </Tooltip>
                                                    <div id={`not-clickable-${comment?._id}`}>
                                                        {truncateString(comment.content.remark, 20)}
                                                    </div>
                                                </div> :
                                                comment.content.remark
                                            }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </CardBody>
                </Card>
            )}
        </div>
    );
};

export default Feedback;
