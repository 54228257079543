import { observer } from "mobx-react-lite";
import { read, utils, writeFile } from 'xlsx';
import React, { useContext, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import {
    Button, Card, CardBody, Col, Form, Row
} from "reactstrap";
import { formatBytes } from "../../components";
import { I18nContext } from "../../store/context/i18nContext";
import { MDBDataTable } from "mdbreact"
import "./datatables.scss"

const FileUploadViewer = (props) => {
    const [data, setData] = useState({ rows: [], columns: [] });
    const [dataHeader, setDataHeader] = useState([]);
    const [selectedFiles, setselectedFiles] = useState([])
    const { t } = useContext(I18nContext);

    function handleAcceptedFiles(files, i) {
        if (files.length) {

            files.map(file =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                    formattedSize: formatBytes(file.size),
                })
            )
            setselectedFiles([files[0]])
            const file = files[0];
            const reader = new FileReader();
            reader.onload = (event) => {
                const wb = read(event.target.result);
                const sheets = wb.SheetNames;
                if (sheets.length) {
                    const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
                    console.log(rows)
                    setData({ rows: rows, columns: Object.keys(rows[0]).map((e, i) => ({ label: e, field: e, sort: "asc", width: 150 })) })
                }
            }
            reader.readAsArrayBuffer(file);
        }
    }

    const handleExport = () => {
        const headings = [dataHeader];
        const wb = utils.book_new();
        const ws = utils.json_to_sheet([]);
        utils.sheet_add_aoa(ws, headings);
        utils.sheet_add_json(ws, data, { origin: 'A2', skipHeader: true });
        utils.book_append_sheet(wb, ws, 'Report');
        writeFile(wb, 'Movie Report.xlsx');
    }

    return (
        <>
            <div className={props?.className}>
                {/* <div>
                    <div className="col-md-6">
                        <button onClick={handleExport} className="btn btn-primary float-right">
                            Export <i className="fa fa-download"></i>
                        </button>
                    </div>
                </div> */}
                <Card className="mx-0 px-2">
                    <Form>
                        <Dropzone
                            onDrop={acceptedFiles => {
                                handleAcceptedFiles(acceptedFiles)
                            }}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div className="dropzone">
                                    <div
                                        className="dz-message needsclick"
                                        {...getRootProps()}
                                    >
                                        <input {...getInputProps()} />
                                        <> <div className="mb-3">
                                            <i className="display-4 text-muted uil uil-cloud-upload" />
                                        </div>
                                            <h4>Drop files here or click to upload.</h4> </>

                                    </div>
                                </div>
                            )}
                        </Dropzone>
                        <Row className=" dropzone-preview mt-3">
                            {selectedFiles.map((f, i) => {
                                return (
                                    <Col key={i} className="">
                                        <Card
                                            style={{ backgroundColor: "#49505799", color: "#fff" }}
                                            className="dz-files-height mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                            key={i + "-file"}
                                        >
                                            <Button
                                                color="danger"
                                                className="btn-rounded waves-effect waves-light __dz-close-btn"
                                                onClick={() => {
                                                    setselectedFiles([])
                                                    setData([])
                                                    setDataHeader([])
                                                }}
                                            >
                                                <i style={{ fontSize: "20px" }} className="bx bx-x" />
                                            </Button>
                                            <div className="p-2">
                                                <Row className="align-items-center">
                                                    <Col>
                                                        <Link
                                                            to="#"
                                                            style={{ color: "#fff" }}
                                                            className="dz-filename font-weight-bold"
                                                        >
                                                            <span className="dz-file-name">{f.name.substring(0, 7)}...</span>
                                                            <span className="dz-file-fullname">{f.name}</span>
                                                        </Link>
                                                        <p className="dz-file-size mb-0">
                                                            <strong>{f.formattedSize}</strong>
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Card>
                                    </Col>

                                )
                            })}
                        </Row>
                    </Form>
                </Card>
                <Card>
                    <CardBody>
                        <MDBDataTable hover responsive striped bordered data={data} />
                    </CardBody>
                </Card>
            </div>
        </>

    );
};

export default observer(FileUploadViewer);
