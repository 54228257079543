import React from 'react'
import { QuestionRadioGroup } from '../../components/IndividualLite/DeclarationForm';
import CustomTextArea from '../../../../../components/InputField/CustomTextArea';
import { InputField, SelectField, SelectFieldMultiple } from '../../../../../components';
import { Row } from 'reactstrap';

const Page4 = ({ asLabel, data, handleChange, hasError, handleValidation,validationkeyValue }) => {
    return (
        <div>
            <Row className='my-2'>
                <QuestionRadioGroup
                    style={{ fontSize: 15 }}
                    field={"crypto"}
                    question="Does Your Company deal in cryptocurrency?"
                    data={data}
                    handleChange={handleChange}
                    hasError={hasError}
                    isDisabled={asLabel}
                    showErr={hasError}
                    error={handleValidation("crypto", data?.crypto)}
                    required
                />

            </Row>

            <h3 style={{ fontWeight: 'bolder' }} className='pt-2'>
                What is a Money Services Business?
            </h3>
            <p>
                A Money Services Business (MSB) refers to a type of financial service provider that offers various services related to money transfers, currency exchange, and payment processing.
            </p>

            <Row className='my-2'>
                <QuestionRadioGroup
                    style={{ fontSize: 15 }}
                    field={"Money_Services_Business"}
                    question="Is your company a Money Services Business or Money Transmitter?"
                    data={data}
                    handleChange={handleChange}
                    hasError={hasError}
                    isDisabled={asLabel}
                    showErr={hasError}
                    error={handleValidation("Money_Services_Business", data?.Money_Services_Business)}
                    // defaultValue={false}
                    required
                />
            </Row>

            <CustomTextArea
                labelStyle={{ fontSize: 15 }}
                placeholder=""
                value={data?.primaryBusinessModel}
                onChange={(v) => { handleChange("primaryBusinessModel", v) }}
                error={handleValidation("primaryBusinessModel", data?.primaryBusinessModel)}
                showErr={hasError}
                type="text"
                label="What is your primary business model as an Money Service Business or Money Transmitter?"
                className="my-3"
                required
                isDisabled={asLabel}
            />

            <h3 style={{ fontWeight: 'bolder' }} className='pt-2'>
                What is a Merchant Acquirer and PSP?
            </h3>
            <p>
                A Merchant Acquirer is a financial institution or payment processor that facilitates the processing of credit or debit card transactions for a merchant. A Payment Service Provider (PSP) allows merchants to accept a wide variety of online payments, including credit cards, digital wallets, and bank transfers.
            </p> <Row className='my-2'>
                <QuestionRadioGroup
                    style={{ fontSize: 15 }}
                    field={"Merchant_Acquirer_PSP"}
                    question="Is your business a merchant acquirer, PSP or similiar?"
                    data={data}
                    handleChange={handleChange}
                    hasError={hasError}
                    showErr={hasError}
                    error={handleValidation("Merchant_Acquirer_PSP", data?.Merchant_Acquirer_PSP)}
                    isDisabled={asLabel}
                    // defaultValue={false}
                    required
                />
            </Row>

            <Row className='my-2'>
                <QuestionRadioGroup
                    style={{ fontSize: 15 }}
                    field={"Regulated_Disclosure"}
                    question="Do you require a license to operate your business model in your jurisdiction of registration?"
                    data={data}
                    handleChange={handleChange}
                    hasError={hasError}
                    showErr={hasError}
                    isDisabled={asLabel}
                    error={handleValidation("Regulated_Disclosure", data?.Regulated_Disclosure)}
                    // defaultValue={false}
                    required
                />
            </Row>

{/* Verify again */}

            {/* {
                !!data?.Regulated_Disclosure ? (
                    <Row className='my-2'>
                        <QuestionRadioGroup
                            style={{ fontSize: 15 }}
                            field={"lisc"}
                            question="Do you require a license to operate your business model in your jurisdiction of registration?"
                            data={data}
                            handleChange={handleChange}
                            hasError={hasError}
                            isDisabled={asLabel}
                            showErr={hasError}
                            defaultValue={false}
                            required
                        />
                    </Row>
                ) : null
            } */}

            <SelectFieldMultiple
                className="my-3"
                labelStyle={{ fontSize: 15 }}
                onChange={(v) => handleChange('Regulatory_Agency', v?.map(v => v?.value))}
                error={handleValidation("Regulatory_Agency", data?.Regulatory_Agency)}
                showErr={hasError}
                isDisabled={asLabel}
                required={validationkeyValue["Regulatory_Agency"]?.required}   
                data={validationkeyValue["Regulatory_Agency"]?.options}
                label={validationkeyValue["Regulatory_Agency"]?.label}
                placeholder={validationkeyValue["Regulatory_Agency"]?.placeholder}
                value={data?.Regulatory_Agency ? validationkeyValue?.Regulatory_Agency?.options?.filter(c => data?.Regulatory_Agency?.includes(c?.value)) : ""}
            />


            <SelectField
                labelStyle={{ fontSize: 15 }}
                className="my-4"
                label="If you are approved for a Business Plus account, do you want to talk to sales to upgrade to a Business Prime account."
                value={data?.talkToSales}
                onChange={(v) => handleChange('talkToSales', v?.value)}
                data={[
                    { label: "Yes", value: true },
                    { label: "No", value: false },
                ]}
                placeholder="Select"
                error={handleValidation("talkToSales", data?.talkToSales)}
                showErr={hasError}
                
                required
            />
        </div>
    )
}

export default Page4;