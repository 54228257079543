import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "../Constant";
import { doGET, doPOST } from "../../../util/HttpUtil";

class TransferGroupSelect {
    transferGroups = [];
    constructor() {
        makeAutoObservable(this);
    }
    fetch = async function (filterData) {
        try {
            const response = await doGET(ENDPOINTS.getAllTransferGroups);
            if (response.status === 200) {
                //;
                this.transferGroups = response.data.map((v) => { return { label: v.name, value: v._id } });
            } else {
                this.error = response.data;
            }
        } catch (err) {
            this.error = err;
        }
    };
}

const TransferGroupSelectService = new TransferGroupSelect();
export default TransferGroupSelectService;
