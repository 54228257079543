import React from 'react'
import { QuestionRadioGroupMultiple } from './Page6'
import { Row } from 'reactstrap'
import { CountrySelect, InputField, SelectField } from '../../../../../components'
import PercentageInput from './PercentageInput'
import YearPicker from '../../../../../components/DateSelects/YearPicker'
import moment from 'moment'
import CheckBoxFieldMutiple from '../../../../../components/CheckBox/CheckBoxFieldMutiple'

const Entity = ({ asLabel, data, validationkeyValue, handleChange, hasError, index, handleValidation }) => {
    return (
        <div>
            <QuestionRadioGroupMultiple
                field={`entities[${index}].relations`}
                question="Relationship with the applying entity."
                data={data}
                handleChange={handleChange}
                isDisabled={asLabel}
                style={{ fontSize: 15 }}
                error={handleValidation(`entities[].relations`, data?.entities?.[index].relations)}
                showErr={hasError}
                required
                options={validationkeyValue["entities[].relations"]?.options?.map((v, index) => { return { ...(v ?? {}), key: index } })}
            />

            <Row>
                <InputField
                    className="col-sm-6"
                    label={validationkeyValue["entities[].name"]?.label}
                    placeholder={validationkeyValue["entities[].name"]?.placeholder}
                    required
                    value={data?.entities?.[index]?.name}
                    onChange={(v) => handleChange(`entities[${index}].name`, v)}
                    error={handleValidation(`entities[].name`, data?.entities?.[index]?.name)}
                    showErr={hasError}
                    type="text"
                    isDisabled={asLabel}
                />

                <SelectField
                    data={validationkeyValue["Business_Registration_Country"]?.options}
                    value={data?.entities?.[index]?.incorpCountry}
                    onChange={(v) => handleChange(`entities[${index}].incorpCountry`, v?.value)}
                    error={handleValidation('entities[].incorpCountry', data?.entities?.[index]?.incorpCountry, data)}
                    showErr={hasError}
                    label={validationkeyValue["entities[].incorpCountry"]?.label}
                    placeholder={validationkeyValue["entities[].incorpCountry"]?.placeholder}
                    isDisabled={asLabel}
                    required
                    className="col-6"
                />


                <InputField
                    value={data?.entities?.[index]?.website}
                    label={validationkeyValue["entities[].website"]?.label}
                    placeholder={validationkeyValue["entities[].website"]?.placeholder}
                    onChange={(v) => handleChange(`entities[${index}].website`, v)}
                    type="text"
                    isDisabled={asLabel}
                    error={handleValidation('entities[].website', data?.entities?.[index]?.website)}
                    showErr={hasError}
                    className="col-sm-6"
                />

                <InputField
                    label={validationkeyValue["entities[].businessNature"]?.label}
                    placeholder={validationkeyValue["entities[].businessNature"]?.placeholder}
                    required
                    value={data?.entities?.[index]?.businessNature}
                    onChange={(v) => handleChange(`entities[${index}].businessNature`, v)}
                    error={handleValidation(`entities[].businessNature`, data?.entities?.[index]?.businessNature)}
                    showErr={hasError}
                    type="text"
                    className="col-sm-6"
                    isDisabled={asLabel}
                />

                <YearPicker
                    value={data?.entities?.[index]?.yearOfIncorporation}
                    onChange={(v) => handleChange(`entities[${index}].yearOfIncorporation`, v)}
                    label={validationkeyValue["entities[].yearOfIncorporation"]?.label}
                    placeholder={validationkeyValue["entities[].yearOfIncorporation"]?.placeholder}
                    required
                    error={handleValidation(`entities[].yearOfIncorporation`, data?.entities?.[index]?.yearOfIncorporation)}
                    maxYear={moment().year()}
                    format="yyyy"
                    isDisabled={asLabel}
                    showErr={hasError}
                    className="col-sm-6"
                />

                {data?.entities?.[index].relations === "shareholder" ?
                    <PercentageInput
                        className="col-sm-6"
                        label={validationkeyValue["entities[].percentOwner"]?.label}
                        placeholder={validationkeyValue["entities[].percentOwner"]?.placeholder}
                        required
                        value={data?.entities?.[index]?.percentOwner ? data?.entities?.[index]?.percentOwner : ""}
                        onChange={(e) => {
                            handleChange(`entities[${index}].percentOwner`, e);
                        }}
                        isDisabled={asLabel}
                        error={handleValidation(`entities[].percentOwner`, data?.entities?.[index]?.percentOwner)}
                        showErr={hasError}

                    />
                    : null}


                {data?.entities?.[index].relations === "shareholder" ?
                    <CheckBoxFieldMutiple
                        labelStyle={{ fontSize: 15 }}
                        label={validationkeyValue["entities[].sourceOfWealthList"]?.label}
                        data={validationkeyValue["entities[].sourceOfWealthList"]?.options?.map((v, index) => { return { ...(v ?? {}), key: index } })}
                        placeholder={validationkeyValue["entities[].sourceOfWealthList"]?.placeholder}
                        value={data?.entities?.[index]?.sourceOfWealthList ? data?.entities?.[index]?.sourceOfWealthList : []}
                        onChange={(v) => {
                            handleChange(`entities[${index}].sourceOfWealthList`, v)
                        }}
                        error={handleValidation('entities[].sourceOfWealthList', data?.entities?.[index]?.sourceOfWealthList)}
                        showErr={hasError}
                        isDisabled={asLabel}
                        required
                        desc="Select all that apply. Note, you may be asked to provide evidence of your source of wealth."
                    />
                    : null}


            </Row>


        </div>

    )
}

export default Entity;