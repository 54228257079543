import { makeAutoObservable } from "mobx";
import { doGET } from "../../../util/HttpUtil";
import { ENDPOINTS } from "./CountrySelectConstant";

class CountrySelect {
  countries = [];
  constructor() {
    makeAutoObservable(this);
  }
  fetch = async function (filterData) {
    try {
      if (this.countries.length > 0) return;
      const response = await doGET(ENDPOINTS.getAllCountries);
      if (response.status === 200) {
        this.countries = response.data.rows;
      } else {
        this.error = response.data;
      }
    } catch (err) {
      this.error = err;
    }
  };
}

const CountrySelectService = new CountrySelect();
export default CountrySelectService;
