import { ThreeBounce } from "better-react-spinkit";
import React, { useContext, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import { doDELETE, doGET, doPOST } from "../../util/HttpUtil";
import PrimaryButton from "../Buttons/PrimaryButton";
import DateSelectCustom from "../DateSelects/AntDateSelect";
import DocTypeSelect from "./DocTypeSelect";
import DropZone from "./Dropzone";
import DropzoneOnline from "./DropzoneOnline";

const DocFieldSkeleton = ({ showCategory, showExpiry }) => {
  return (
    <div className="fv_application_doc  rounded-2 border p-3">
      <div className="d-flex align-items-center row mb-2 ">
        <div className="col-3">
          <Skeleton height={30} />
        </div>
        <div className="col-3">

          <Skeleton height={30} />
        </div>

      </div>

      <div className="mx-1">
        <Skeleton height={50} />
      </div>
      <Row className="d-flex justify-content-between">
        {showCategory && (
          <div className="mt-2 col-lg-4 col-sm-12">
            <Skeleton height={30} />
          </div>
        )}
        {(showExpiry) && (
          <div className="mt-2  col-lg-5 col-sm-12">
            <Skeleton height={30} />
          </div>
        )}
        <div className="mt-2 col-lg-3 col-sm-12  d-flex justify-content-end">
          <Skeleton width={100} height={30} />
        </div>
      </Row>
    </div>
  );
};
const DocField = ({
  key = 1,
  showCategory,
  hideUpload,
  value,
  onChange = () => { },
  data = { attachment: { name: "", info: "", refNo: "", urls: [] }, version: 0 },
  record_id = "",
  getRecordId = async () => { },
  module = "",
  inline,
  field,
  asLabel,
  showExpiry,
  type,
  ubo_id,
  onSubmit = () => { },
  seq,
  enableOnline,
  supportedFiles = ["image", "pdf"],
  single = false,
  error  = ""
}) => {
  const { showError, showMessage, showConfirm } = useContext(DialogContext);
  const { t } = useContext(I18nContext);
  const [document, setDocument] = useState(data);
  const [loading, setLoading] = useState(false);


  const handleSaveCard = async (document, updatedField) => {

    // Define the supported file extensions based on the supportedFiles prop
    const supportedFileExtensions = supportedFiles.reduce((acc, fileType) => {
      if (fileType === "image") {
        acc.push(".png", ".gif", ".jpeg", ".jpg");
      } else if (fileType === "pdf") {
        acc.push(".pdf");
      }
      return acc;
    }, []);
    const invalidFiles = (document?.attachment?.urls ?? []).filter(url => {
      if (url != undefined && (url?.name || url?.key)) {
        const fileExtension = (url?.name ?? url?.key).slice((url?.name ?? url?.key).lastIndexOf("."));
        return !supportedFileExtensions.includes(fileExtension.toLowerCase());
      } return true
    });
    if (invalidFiles.length > 0 ) {
      showError(`Unsupported file format detected. Please upload only ${supportedFiles.join(" or ")} files.`);
      return;
    }

    setLoading(true);
    
    const formData = new FormData();
    const formUrls = document?.attachment?.urls?.filter(url => url?.key?.slice(1, 13) === "api/o/assets");
    document?.attachment?.urls?.forEach(url => {
      if (url.key.slice(1, 13) !== "api/o/assets") formData.append("file", url);
    });

    let recordId = record_id || await getRecordId();
    const docObject = {
      record_id,
      module,
      expiry: document?.expiry,
      version: document.version,
      docCategory_id: document?.docCategory_id,
      ubo_id,
      form_id: recordId,
      type,
      ...(seq && { seq }),
      attachment: { ...document.attachment, urls: formUrls },
      ...(data._id && { _id: data._id }),
      ...(field && { field })
    };
    formData.append("data", JSON?.stringify(docObject));

    try {
      const response = await doPOST(`/api/${module ? "file" : "application-doc"}/upload`, formData);
      if (response.status === 200) {
        showMessage(updatedField == "docCategory_id" ? "Updated Document Category" : "Document Uploaded");
        setDocument(response.data);
        onChange(response.data._id);
        onSubmit();
      } else if (response?.status === 413) {
        showError(response?.data);
        return null
      }
    } catch (error) {
      showError(error);
      return null
    } finally {
      setLoading(false);
    }
  };

  const handleAttachmentDelete = async (index) => {
    if (await showConfirm({ title: t("Do you want to delete file?"), description: t("This is an unrecoverable operation.") })) {
      try {
        const response = await doDELETE(`/api/${module ? "file" : "application-doc"}/delete/attachment`, {
          record_id, _id: document._id, module, index
        });
        if (response.status === 200) {
          setDocument(response.data);
          showMessage("Deleted");
          return true;
        }
      } catch (e) {
        showError(e);
      }
    }
    return false;
  };

  const fetchDocument = async () => {
    try {
      const response = await doGET(`/api/file/${value}/detail`);
      if (response?.status === 200) setDocument(response.data);
    } catch (error) {
      showError(error);
    }
  };

  useEffect(() => {
    if (value && module) fetchDocument();
  }, [value]);

  useEffect(() => setDocument(data), [data]);

  const handleFieldChange = async (field, value) => {
    const updatedDocument = { ...document, [field]: value };
    const result = await handleSaveCard(updatedDocument, field);

    if (result !== null) {
      setDocument(updatedDocument);
    }
  };


  return (
    <div className="border border-light rounded-2 border" key={key}>
      <div className="position-relative p-3">
        {loading ? (
          <DocFieldSkeleton showCategory={showCategory} showExpiry={showExpiry} />
        ) : (
          <div>
              {(error?.length && !document?.attachment?.urls?.length && !loading) ? <em className="selectfield__error">{error}</em> : null}

            <div className="mx-1">
              {enableOnline ? <DropzoneOnline
                asLabel={asLabel}
                value={document?.attachment?.urls}
                inline={inline}
                onDeleteAttachment={handleAttachmentDelete}
                onChange={(v) => handleFieldChange('attachment', { ...(document?.attachment ?? {}), urls: v })}
              /> : <DropZone
                asLabel={asLabel}
                value={document?.attachment?.urls}
                inline={inline}
                onDeleteAttachment={handleAttachmentDelete}
                supportedFiles={supportedFiles}
                single={single}

                onChange={(v) => handleFieldChange('attachment', { ...(document?.attachment ?? {}), urls: v })}
              />}
            </div>
            <Row className="d-flex justify-content-between">
              {showCategory && (
                <DocTypeSelect
                  module={showCategory}
                  placeholder="Select Document Type"
                  label="Select Document Type"
                  value={document?.docCategory_id}
                  onChange={({ value }) => handleFieldChange('docCategory_id', value)}
                  className="mt-3 col-lg-4 col-sm-12"
                />
              )}
              {(asLabel && showExpiry) && (
                <DateSelectCustom
                  value={document?.expiry}
                  placeholder="dd/mm/yyyy"
                  onChange={(e) => handleFieldChange('expiry', e)}
                  label={t("Expiry Date")}
                  isDisabled={asLabel}
                  required
                />
              )}
              {showExpiry && (
                <DateSelectCustom
                  value={document?.expiry}
                  placeholder="dd/mm/yyyy"
                  onChange={(e) => handleFieldChange('expiry', e)}
                  label={t("Expiry Date")}
                  isDisabled={asLabel}
                  required
                  type="text"
                  className="mb-0 col-lg-5 col-sm-12"
                  minDate={new Date()}
                />
              )}
              {(!asLabel && !hideUpload) && (
                <div className="mt-3 col-lg-3 col-sm-12  mt-5 d-flex justify-content-end " >
                  <PrimaryButton
                    style={{ fontSize: 12 }}
                    loading={loading}
                    isDisabled={loading || !document?.attachment?.urls?.length || (showExpiry && !document?.expiry)}
                    onClick={() => handleSaveCard(key)}
                  >
                    {loading ? <ThreeBounce color="white" size={8} /> : t("Upload")}
                  </PrimaryButton>
                </div>
              )}
            </Row>
          </div>
        )}
      </div>
    </div>
  );
};

export default DocField;
