import React from 'react'
import { Button } from 'reactstrap'

export const FilterModalButton = (props) => {

    return (
        <Button
            color="primary"
            style={{
                position: "relative",
                padding: "0px"
            }}
            onClick={props?.onClick}
        >
            <i style={{ fontSize: "24px", margin: "2px 2px" }} className="bx bx-search-alt-2" />
            {props?.filterLength ? <span style={{ position: "absolute", top: "-10px", right: "-5px" }} className="badge bg-danger rounded-pill">{props?.filterLength}</span> : null}
        </Button>
    )
}
