import React, { useState, useEffect } from "react";
import { doGET, doPUT } from "../../util/HttpUtil";

export const I18nContext = React.createContext({});

export const I18nProvider = (props) => {

    const [translations, setTranslations] = useState({});
    const [lang, setLang] = useState("en");

    useEffect(() => {
        const init = async () => {
            let myLang = localStorage.getItem("lang");
            if (myLang && myLang !== 'undefined')
                setLang(myLang);

            // if (localStorage.getItem("BearerToken") != null) {
            //     try {
            //         myLang = await doGET("/api/ui-preference/lang/detail");
            //         if (myLang && myLang?.data?.value) {
            //             setLang(myLang?.data?.value);
            //             localStorage.setItem("lang", myLang?.data?.value);
            //         }
            //     } catch (e) {
            //         console.log(e)
            //     }
            // }
        }
        init();
    }, [])

    const fetchAllTranslations = async () => {
        try {
            const t = await doGET("/api/o/i18n/" + lang + "/all");
            setTranslations({ ...translations, [lang]: t.data });
        } catch (error) {
            console.log("error fetching translation")
        }
    };

    const changeLang = async (language) => {
        localStorage.setItem("lang", language.code);
        setLang(language.lang);
        try {
            await doPUT("/api/ui-preference/update", { key: "lang", value: language.lang });
        } catch (e) { }
    }

    useEffect(() => {
        if (lang) {
            if (lang === "en") {
                setTranslations({ ...translations, en: "translation" });
            } else if (!translations[lang]) {
                fetchAllTranslations();
            }
        }
    }, [lang]);

    const t = (key) => {
        if (!lang || lang === "en") {
            return key;
        }
        if (translations[lang] && translations[lang][key]) {
            return translations[lang][key];
        }

        // if (!translations[lang] || !translations[lang][key]) {
        doGET("/api/o/i18n/translate?lang=" + lang + "&key=" + key);
        // }
        return key;
    };

    return (
        <I18nContext.Provider
            value={{
                lang,
                t,
                changeLang,
                translations
            }}
        >
            {props?.children}
        </I18nContext.Provider>
    );
};
